<template>
  <v-dialog
      v-model="dialog"
      width="800px"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} localisation</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12" >
              <v-select :items="types" item-value="value" item-text="text"
                        placeholder="Select field to customise"
                        outlined :error="$v.score_setting_text.type.$error"
                        dense
                        v-model="score_setting_text.type"
              >
                <template v-slot:label>
                  Select field to customise <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="errors.type" >{{errors.type[0]}}</span>
              <span class="text-danger" v-if="$v.score_setting_text.type.$error">This information is required</span>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label>
                <strong>Value</strong>
              </label>
              <ckeditor
                  :config="editorConfig"
                  v-model="score_setting_text.value" >
              </ckeditor>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            x-large
            text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            x-large
            dark
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>


      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ScoreSettingTextService from "@/services/score/score-setting-text/ScoreSettingTextService";
import ScoreService from "@/services/score/score/ScoreService";
import {numeric, required, requiredIf} from "vuelidate/lib/validators";
import ScoreSettingService from "@/services/score/score-setting/ScoreSettingService";
const scoreSetting=new ScoreSettingService;
const score = new ScoreService();
const scoreSettingText=new ScoreSettingTextService;
export default {
  validations:{
    score_setting_text:{
      type:{required},
    }
  },
  data(){
    return{
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },

      title:'',
      dialog: false,
      edit: false,
      loading: false,
      types:[],
      score_setting_text:{
        score_id:'',
        type:'',
        value:'',
      },
      scores:[],
      errors:[],
    }
  },
methods:{
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
      this.$v.$reset();
      this.$emit('refresh');
    },
    openDialog() {
      this.dialog = true;
    },
    createScoreSettingText() {


      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editScoreSettingText(item) {
      this.openDialog();
      this.edit = true;
      this.score_setting_text = item;
      this.title = "Edit";
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function () {

      this.score_setting_text.score_id=this.scoreId;
      this.loading = true;
      scoreSettingText
          .create(this.score_setting_text)
          .then(response => {
            this.$snotify.success("Configuration added");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {

            this.errors = error.errors;
          })
    },
    update: function () {
      this.loading = true;
      scoreSettingText
          .update(this.score_setting_text.id, this.score_setting_text)
          .then(response => {
            this.$snotify.success("Configuration updated");
            this.closeDialog();
            this.resetForm();
            this.errors=[];
            this.loading = false;
            this.$emit('refresh');
          })
          .catch(error => {
            this.errors = error.errors;
          })
          .catch((err) => {
            this.loading = false;
            this.errors = err.response.data.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    getAllScoreSettingType() {
      scoreSetting
      .getScoreSettingText()
        .then(response => {
          for(let key in response.data.score_setting_texts){
            this.types.push({value:response.data.score_setting_texts[key], text:response.data.score_setting_texts[key]})
          }
        })
    },
    scoreSettingTextDetail() {
      if(this.scoreSettingTextId != null || this.scoreSettingTextId !=undefined ){
        this.title='Update';
        scoreSettingText
            .show(this.scoreSettingTextId)
            .then(response=> {
              this.score_setting_text = response.data.scoreStateText;
            })
      }else {
        this.title='Create';
        this.score_setting_text={};
      }
    },
    resetForm(){
         this.score_setting_text={};
    },
  },
  computed:{
    scoreId() {
      return this.$route.params.scoreId;
    },

  },
  mounted() {
    this.getAllScore();
    this.getAllScoreSettingType();
    this.score_setting_text.type;
  }
}
</script>