import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ScoreSettingService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/score-setting';
    }
    getScoreSettingText(){
        let url = `${this.#api}/score-setting-text`;
        return apiService.get(url);
    }
}