<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>SCORE localisation - {{score.name}}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ {{score.name}} \ SCORE localisation
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="createScoreSettingText()"
                      class="btn btn-primary font-weight-bolder font-size-sm"
                      style="color: #fff;">
                    <i class="fa fa-plus"></i>
                    Add localisation
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Type</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-if="score_setting_texts.length > 0" v-for="item in score_setting_texts">
                    <td class="px-2">

                      <a @click="editScoreSettingText(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.type }}
                      </a>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editScoreSettingText(item)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  @click="deleteScoreSettingText(item.id)" class="navi-link">
                               <span class="navi-icon">
                                   <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="score_setting_texts.length == 0">
                    <td colspan="3" class="text-center">
                      <strong>No text localisation available</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="score_setting_texts.length > 0"
                  class="pull-right mt-7"  :disabled="isLoading"
                  @input="getAllScoreSettingText"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <create-or-update ref="create-or-update" @refresh="getAllScoreSettingText"></create-or-update>
    </div>
  </v-app>
</template>
<script>
import ScoreSettingTextService from "@/services/score/score-setting-text/ScoreSettingTextService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateOrUpdate from './CreateOrUpdate';

const score = new ScoreService();
const scoreSettingText=new ScoreSettingTextService;
export default
{
  components:{
    CreateOrUpdate,
  },
  data(){
    return{
      score_setting_texts:[],
      search:{
        score_id:'',
      },
      score:{},
      scores:[],
      total: null,
      perPage: null,
      page: null,
      isLoading:false,
    }
  },
  methods:{
    getAllScoreSettingText(){
      this.isLoading=false;
      scoreSettingText
          .paginate(this.search, this.page)
          .then(response => {
            this.score_setting_texts=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.isLoading=false;
          })
          .catch((err) => {

          });
    },

    deleteScoreSettingText(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            scoreSettingText
                .delete(item)
                .then((response) => {
                  this.getAllScoreSettingText();
                  this.$snotify.success("Score Setting Text Sucessfully Deleted");
                })
                .catch((err) => {
                  this.isLoading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    createScoreSettingText(){
      this.$refs['create-or-update'].createScoreSettingText(this.scoreId);
    },
    editScoreSettingText(item) {
      this.$refs['create-or-update'].editScoreSettingText(item);
    },
    getScoreDetail(){
      score
          .show(this.scoreId)
          .then(response => {
            this.score = response.data.score;
          })
          .catch((err) => {

          });
    },
    getAllScore(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    searchScoreSettingText(){
      this.getAllScoreSettingText();
    }
  },
  computed:{
    scoreId() {
      return this.$route.params.scoreId;
    },

  },
  mounted() {
    this.getAllScore();
    this.search.score_id=this.scoreId;
    this.getScoreDetail()
    this.searchScoreSettingText();
  }
}
</script>